import * as assert from "assert";
import {
  getSnapshot,
  IModelType,
  isModelType,
  isType,
  IType,
} from "mobx-state-tree";

type JsonEncoders = Map<any, (value: any) => string | number>;
type JsonDecoders = {
  [key: string]: (json: any) => any;
};
const baseEncoders: JsonEncoders = new Map<any, (value: any) => any>([
  [Date, (value: Date) => value.toISOString()],
  [Error, (value: Error) => value.message],
  [Buffer, (value: Buffer) => "\\x" + value.toString("hex")],
]);
const baseDecoders: JsonDecoders = {
  Date: (json: string) => new Date(json),
  Error: (json: string) => new Error(json),
  Buffer: (json: string) => Buffer.from(json.slice(2), "hex"),
  undefined: () => undefined,
};

export const escapeQuotes = (s: string) => JSON.stringify(s).slice(1, -1);

// s.replace(/\\/g, '\\\\').replace(/"/g, '\\"').replace(/\n/g, '\\n');

export class Jsoncoder {
  encoders: JsonEncoders;
  decoders: JsonDecoders;
  model: Map<string, IType<any, any, any>> = new Map();

  constructor(
    encoders: JsonEncoders,
    decoders: JsonDecoders,
    model: IModelType<any, any, any, any>[] = []
  ) {
    // @ts-ignore
    this.encoders = new Map([...baseEncoders, ...encoders]);
    this.decoders = { ...baseDecoders, ...decoders };
    this.setModel(model);
  }

  public setModel(model: IType<any, any, any>[]) {
    this.model = new Map(model.map((m) => [m.name, m]));
  }

  public stringify(value: any): string {
    if ([null, true, false].includes(value) || typeof value === "number") {
      return `${value}`;
    } else if (typeof value === "undefined") {
      return `"<undefined>null"`;
    } else if (typeof value === "string") {
      return `"${value[0] === "<" ? "<" : ""}${escapeQuotes(value)}"`;
    } else if (typeof value === "object") {
      if (Array.isArray(value)) {
        return `[${value.map((item) => this.stringify(item)).join(",")}]`;
      } else if (value.$treenode?.type) {
        const className = value.$treenode?.type.name;
        const json = JSON.stringify(getSnapshot(value));
        return `"<[MST]${className}>${escapeQuotes(json)}"`;
      } else if (!value.constructor || value.constructor.name === "Object") {
        return `{${Object.entries(value)
          .map(
            ([key, value]) => `"${escapeQuotes(key)}":${this.stringify(value)}`
          )
          .join()}}`;
      } else if (this.encoders.has(value.constructor)) {
        const className = value.constructor.id || value.constructor.name;
        const result = this.encoders.get(value.constructor)!(value);
        const json = this.stringify(result);
        return `"<${className}>${escapeQuotes(json)}"`;
      } else if (value.toJson) {
        const className = value.constructor.id || value.constructor.name;
        const result = value.toJson();
        const json =
          typeof result === "string" ? result : this.stringify(result);
        return `"<${className}>${escapeQuotes(json)}"`;
      } else if (value.constructor.prototype instanceof Error) {
        const className = "Error";
        const result = this.encoders.get(Error)!(value);
        const json = this.stringify(result);
        return `"<${className}>${escapeQuotes(json)}"`;
      } else {
        // return JSON.stringify(value);
        throw new Error(
          `do not know how to stringify object: ${
            value.constructor ? value.constructor.name : "-"
          }: ${JSON.stringify(value)}`
        );
      }
    } else {
      throw new Error(`do not know how to stringify: ${value}`);
    }
  }

  public parse<T = any>(obj: string, validate: boolean = true): T {
    return JSON.parse(obj, (key, value): any => {
      if (typeof value === "string" && value[0] === "<") {
        if (value[1] === "<") {
          return value.slice(1);
        } else {
          const [, className, json] = value.match(/^<([^>]*)>([^]*)$/)!;
          assert.ok(className);
          assert.ok(json);
          if (className.startsWith("[MST]")) {
            const name = className.slice(5);
            assert.ok(
              this.model.has(name),
              `jsoncoder does not have name ${name} in model map`
            );
            return this.model.get(name)!.create(JSON.parse(json));
          } else if (className in this.decoders) {
            const decoder = this.decoders[className];
            assert.ok(decoder);
            return decoder(this.parse(json, validate));
          } else {
            throw new Error(
              `not 'className ${className} in this.decoders' error: ${value} , ${obj}`
            );
          }
        }
      } else {
        return value;
      }
    });
  }
}

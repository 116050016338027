import BuildIcon from "@mui/icons-material/Build"
import HistoryIcon from "@mui/icons-material/History"
import ListIcon from "@mui/icons-material/List"
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd"
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer"
import SearchIcon from "@mui/icons-material/Search"
import { AppBar, IconButton, Toolbar } from "@mui/material"
import * as React from "react"
import { Link } from "react-router-dom"

export default () => (
  <AppBar position="static">
    <Toolbar style={{ justifyContent: "space-around" }}>
      <IconButton
        aria-label="test"
        color="inherit"
        component={Link}
        to={"/test"}
      >
        <QuestionAnswerIcon />
      </IconButton>
      <IconButton
        aria-label="search"
        color="inherit"
        component={Link}
        to={"/search"}
      >
        <SearchIcon />
      </IconButton>
      <IconButton
        aria-label="decklist"
        color="inherit"
        component={Link}
        to={"/decklist"}
      >
        <ListIcon />
      </IconButton>{" "}
      <IconButton
        aria-label="addcard"
        color="inherit"
        component={Link}
        to={"/addcard"}
      >
        <PlaylistAddIcon />
      </IconButton>{" "}
      <IconButton
        aria-label="history"
        color="inherit"
        component={Link}
        to={"/history"}
      >
        <HistoryIcon />
      </IconButton>
      <IconButton
        aria-label="testpage"
        color="inherit"
        component={Link}
        to={"/settings"}
      >
        <BuildIcon />
      </IconButton>
    </Toolbar>
  </AppBar>
)

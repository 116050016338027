import { Instance, types } from "mobx-state-tree"

const properties = {
  id: types.identifierNumber,
  top: types.maybeNull(types.integer),
  owner: types.string,
}

export const DeckModel = types.model("deck", properties).actions((self) => ({
  update(updates: Partial<DeckType>): void {
    Object.assign(self, updates)
  },
}))
export interface DeckType extends Instance<typeof DeckModel> {}

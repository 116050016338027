export default <T>(
  target: T,
  propertyKey: keyof T,
  descriptor: PropertyDescriptor
): any => {
  const oldGetter = descriptor.get!;
  descriptor.get = function(this: T) {
    const value = oldGetter.call(this);
    Object.defineProperty(this, propertyKey, {
      value,
      enumerable: true
    });
    return value;
  };
};

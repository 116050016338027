import { Button, ButtonProps, CircularProgress } from "@mui/material";
import * as React from "react";
import { useState } from "react";

interface Props extends ButtonProps {}

export default ({ onClick, children, ...props }: Props) => {
  const [onClickDone, setOnClickDone] = useState(true);

  const onClickHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      setOnClickDone(false);
      await onClick(event);
      setOnClickDone(true);
    }
  };

  return (
    <Button
      {...props}
      onClick={onClickHandler}
      // style={{
      //   ...props.style,
      //   ...(onClickDone
      //     ? { background: "transparent", transition: "background 1s" }
      //     : {
      //         background: "yellow",
      //         transition: "background 0s",
      //       }),
      // }}
    >
      {!onClickDone && <CircularProgress style={{ position: "absolute" }} />}
      {children}
    </Button>
  );
};

// @ts-ignore
var global = {};
import { Buffer as BufferPolyfill } from "buffer";
declare var Buffer: typeof BufferPolyfill;
globalThis.Buffer = BufferPolyfill;

const config = {
  mobx: {
    useProxies: "always",
    enforceActions: "always",
    computedRequiresReaction: true,
    reactionRequiresObservable: false,
    observableRequiresReaction: false,
  },
} as { mobx: any };

export default config;

import { CssBaseline } from "@mui/material"
import { Experimental_CssVarsProvider as CssVarsProvider } from "@mui/material/styles"
import Component from "@scope/standard/ui/Component"
import * as React from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import App from "./containers/App"
import { ClientContext } from "./contexts/ClientContext"
import { ConfigContext } from "./contexts/ConfigContext"
import { ServiceMapContext } from "./contexts/ServiceMapContext"
import { StoreContext } from "./state"
import createTheme from "./theme"

const queryClient = new QueryClient()

export default Component(function Main({
  client,
  config,
  store,
  serviceMap,
  registration,
}: any) {
  return (
    <QueryClientProvider client={queryClient}>
      <CssVarsProvider theme={createTheme(store.fontSize, store.iconSize)}>
        <CssBaseline />
        <ConfigContext.Provider value={config}>
          <ClientContext.Provider value={client}>
            <StoreContext.Provider value={store}>
              <ServiceMapContext.Provider value={serviceMap}>
                <App registration={registration} />
              </ServiceMapContext.Provider>
            </StoreContext.Provider>
          </ClientContext.Provider>
        </ConfigContext.Provider>
      </CssVarsProvider>
    </QueryClientProvider>
  )
})

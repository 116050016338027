import { Instance, types } from "mobx-state-tree";

export const ClientConfigModel = types.model("config", {
  clientId: types.string,
  scope: types.string,
  redirectUri: types.string,
  dataUri: types.string,
});

export interface ClientConfigType extends Instance<typeof ClientConfigModel> {}

import { createContext, useContext } from "react";
import { ClientConfigType } from "../config";

export const ConfigContext = createContext<ClientConfigType | null>(null);

export const useConfig = () => {
  const config = useContext(ConfigContext);
  if (config) {
    return config;
  } else {
    throw new Error("config not set");
  }
};

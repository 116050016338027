import * as assert from "assert";
import { action, observable } from "mobx";
import { io, Socket } from "socket.io-client";
import { Jsoncoder } from "../jsoncoder/jsoncoder";
import { seconds } from "../timestuff/Duration";
import { tryForSomePeriod } from "../timestuff/utils";
import { ServiceMap } from "./common";

const withTimeout = async <T>(
  emit: () => Promise<T>,
  timeout: number,
): Promise<T> =>
  new Promise<T>(async (resolve, reject) => {
    let called = false;

    const timer = setTimeout(() => {
      if (!called) {
        called = true;
        reject(new Error("time out"));
      }
    }, timeout);

    try {
      const result = await emit();
      if (!called) {
        called = true;
        clearTimeout(timer);
        resolve(result);
      }
    } catch (e) {
      if (!called) {
        called = true;
        clearTimeout(timer);
        reject(e);
      }
    }
  });

export default class Client<
  ServerServiceMap extends ServiceMap,
  ClientServiceMap extends ServiceMap,
> {
  public serverServiceMap!: ServerServiceMap;
  @observable connected?: boolean;
  public socket: Socket | null = null;
  public getJwt: null | (() => string) = null;
  private handleIncomingEvents = true;
  public jsonCoder = new Jsoncoder(new Map(), {});

  constructor(
    public uri: string,
    public clientServiceMap: ClientServiceMap,
    public websocket: boolean = true,
    public anonymousAllowed: boolean = false,
  ) {
    this.setServerServiceMap();
  }

  public disconnect() {
    this.socket && this.socket.disconnect();
    this.socket = null;
  }

  public async connect() {
    if (!this.anonymousAllowed && this.getJwt && !this.getJwt()) {
      throw new Error("no oAuthcode or jwt: " + this.getJwt());
    }
    if (this.websocket) {
      this.connectWebsocket();
    }
  }

  public async emit<T>(
    givenOptions:
      | keyof ServerServiceMap
      | { event: keyof ServerServiceMap; timeout?: number },
    ...args: any[]
  ): Promise<T> {
    if (!this.anonymousAllowed && this.getJwt && !this.getJwt()) {
      throw new Error("could not use service because no jwt");
    }
    const defaultOptions = { timeout: 30e3 };
    const options =
      typeof givenOptions === "object"
        ? { ...defaultOptions, ...(givenOptions as any) }
        : { ...defaultOptions, event: givenOptions };

    const json = this.jsonCoder.stringify(args);
    let raw: string;
    return withTimeout<T>(async () => {
      if (this.websocket) {
        raw = await this.emitWebsocket(options.event, json);
      } else {
        raw = await this.emitHttp(options.event, json);
      }
      const result = this.jsonCoder.parse<any>(raw);
      if (result instanceof Error) {
        throw result;
      } else {
        return result;
      }
    }, options.timeout);
  }

  public async emitWebsocket(
    event: keyof ServerServiceMap,
    args: string,
  ): Promise<string> {
    await tryForSomePeriod(() => !!this.connected, { startWithJob: true });
    return new Promise<string>((resolve, reject): void => {
      this.socket &&
        this.socket.emit("service", event, args, (raw: string) => resolve(raw));
    });
  }

  private async connectWebsocket() {
    assert.ok(this.getJwt);
    const jwt = this.getJwt();
    const socket = io(this.uri, {
      query: { token: jwt },
      timeout: 3e3,
    });
    socket.on(
      "service",
      async (name: keyof ClientServiceMap & string, data: string) => {
        try {
          const service = this.clientServiceMap[name];
          if (!service) {
            throw new Error(`Service ${name} does not exist.`);
          }
          if (this.handleIncomingEvents) {
            const args = this.jsonCoder.parse(data);
            if (!Array.isArray(args)) {
              throw new Error(`Variable args is not an array.`);
            }
            service(...args);
          }
        } catch (error: any) {
          console.error("parse error: ", error.constructor.name, error.message);
        }
      },
    );

    for (const event of "connect connect_error connect_timeout connecting disconnect error reconnect reconnect_attempt reconnect_failed reconnect_error reconnecting ping pong".split(
      " ",
    )) {
      socket.on(event, async (...args: []) => {
        console.log("socket event:", event);
      });
    }
    socket.on(
      "reconnect",
      action(async () => {
        this.connected = true;
      }),
    );
    socket.on(
      "connect",
      action(async (...args: any[]) => {
        this.connected = true;
      }),
    );
    socket.on(
      "disconnect",
      action(async () => {
        this.connected = false;
      }),
    );
    await tryForSomePeriod(
      () => (socket.connected ? Promise.resolve() : Promise.reject()),
      { period: seconds(5), interval: seconds(0.1) },
    );
    this.socket = socket;
  }

  private async emitHttp(
    event: Extract<keyof ServerServiceMap, string>,
    args: string,
  ): Promise<string> {
    const url = this.uri + "/api/service/" + event;
    const method = "POST";
    const init: RequestInit = {
      method,
    };
    const jwt = this.getJwt ? this.getJwt() : "";
    if (jwt) {
      init.headers = { Authorization: "Bearer " + jwt };
    }
    if (args) {
      // @ts-ignore
      init.body = args;
    }
    const response = await fetch(url, init);
    return response.text();
  }

  private setServerServiceMap() {
    this.serverServiceMap = new Proxy<ServerServiceMap>(
      {} as ServerServiceMap,
      {
        get:
          (t, key) =>
          (...args: any[]) => {
            assert.ok(typeof key === "string");
            return this.emit<any>(key, ...args);
          },
      },
    );
  }
}

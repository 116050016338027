import { TextareaAutosize } from "@mui/material";
import { TextareaAutosizeProps } from "@mui/material/TextareaAutosize";
import { useState } from "react";
import * as React from "react";

export interface Props extends TextareaAutosizeProps {}

export default ({
  onBlur,
  onFocus,
  onChange,
  value: givenValue,
  ...props
}: Props) => {
  const [value, setValue] = useState(givenValue);
  const [edit, setEdit] = useState(false);
  const handleBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    setEdit(false);
    onBlur && onBlur(event);
  };
  const handleFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    setEdit(true);
    onFocus && onFocus(event);
  };
  if (!edit && givenValue !== value) {
    setValue(givenValue);
  }
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    console.log(1);
    setValue(event.target.value);
    onChange && onChange(event);
  };
  return (
    <TextareaAutosize
      {...props}
      onChange={handleChange}
      value={value}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  );
};

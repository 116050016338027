import * as React from "react";
import { Checkbox, CheckboxProps } from "@mui/material";
import * as assert from "assert";

export default function (props: CheckboxProps & { checked: boolean }) {
  assert.ok(
    props.checked === true || props.checked === false,
    `checked was not true or false: ${props.checked}`
  );
  return <Checkbox {...props} />;
}

import { CheckboxProps } from "@mui/material"
import Box from "@mui/material/Box"
import StandardCheckbox from "@scope/standard/ui/Checkbox"
import React from "react"

const Checkbox = ({
  children,
  ...props
}: CheckboxProps & { children: any; checked: boolean }) => {
  return (
    <Box>
      {/*// @ts-ignore*/}
      <StandardCheckbox {...props} />
      {children}
    </Box>
  )
}

export default Checkbox

import * as assert from "assert"
import * as idbkeyval from "idb-keyval"
import * as jsondiffpatch from "jsondiffpatch"
import { reaction } from "mobx"
import {
  applySnapshot,
  getSnapshot,
  onPatch,
  onSnapshot,
} from "mobx-state-tree"
import { createContext, useContext } from "react"
import { editableCardProperties } from "../../common/domain/Card"
import { StoreModel, StoreType } from "./model/Store"

export const initState = async (): Promise<StoreType> => {
  const state = StoreModel.create()
  const maxDelta = 16
  const initRaw = await idbkeyval.get("state")
  let index = 0
  const diff = new jsondiffpatch.DiffPatcher()
  if (typeof initRaw === "object") {
    let init = initRaw
    const startTime = Date.now()
    while (true) {
      const delta = await idbkeyval.get<any>("delta" + index)
      if (delta === undefined) {
        break
      } else {
        init = diff.patch(init, delta)
        // applyPatch(state,delta)
      }
      index++
    }
    applySnapshot(state, init)
    console.log("patched", Date.now() - startTime)
  } else {
    index = Infinity
  }
  let previousSnapshot = getSnapshot(state)
  let currentSnapshot: any = null
  let waitingSnapshot: any = null

  const process = async (snapshot?: any) => {
    if (snapshot) {
      waitingSnapshot = snapshot
    }
    if (!currentSnapshot && waitingSnapshot) {
      currentSnapshot = waitingSnapshot
      waitingSnapshot = null
      setTimeout(async () => {
        try {
          console.time("onSnapshot")
          if (index < maxDelta) {
            console.time("diff")
            const patch = diff.diff(previousSnapshot, currentSnapshot)
            console.timeEnd("diff")
            console.time("set")
            await idbkeyval.set("delta" + index, patch)
            console.timeEnd("set")
            index++
          } else {
            await idbkeyval.clear()
            await idbkeyval.set("state", currentSnapshot)
            index = 0
          }
          previousSnapshot = currentSnapshot
          currentSnapshot = null
          console.timeEnd("onSnapshot")
          process()
        } catch (e: any) {
          alert(e.message)
        }
      }, 100)
    }
  }

  onSnapshot(state, async (snapshot) => {
    process(snapshot)
  })
  onPatch(state.cards, (...args) => {
    console.log(args)
    if (args[0].op === "replace") {
      const match = args[0].path.match(/^\/(\d+)\/(.+)$/)
      if (match) {
        const [, index, property] = match
        assert.ok(index)
        assert.ok(property)
        const card = state.cards[+index]
        assert.ok(card)
        if (Object.keys(editableCardProperties).includes(property)) {
          console.log(card.id, property, args[0].value)
          state.addDirtyProperty(card.id, property)
        }
      }
    }
  })
  await state.checkIdleCards()
  reaction(
    () => [state.idleCards],
    async () => {
      await state.checkIdleCards()
    }
  )
  // setInterval(async() =>{
  //   const startTime=Date.now()
  //   await state.checkIdleCards();
  //   console.log('checkIdleCards',Date.now()-startTime)
  // }, 1e3);
  // onSnapshot(state, (value) => {
  //   document.documentElement.style.fontSize = `${value.fontSize}px`;
  // });
  // document.documentElement.style.fontSize = `${state.fontSize}px`;
  return state
}
export const StoreContext = createContext<StoreType | null>(null)

export const useStore = () => useContext<StoreType | null>(StoreContext)!

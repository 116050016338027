import { getUrlParameter } from "@scope/standard/utils";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useConfig } from "../contexts/ConfigContext";
import { useStore } from "../state";

const handle = async () => {
  const code = getUrlParameter("code", document.location);
  const store = useStore();
  const config = useConfig();
  const response = await fetch(config.dataUri + "/api/getToken", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ code, redirectUri: config.redirectUri }),
  });
  const jwt = await response.text();
  store.setJwt(jwt);
  console.log("done", store.jwt);
};

export default function () {
  handle();
  const navigate = useNavigate();
  React.useEffect(() => navigate("/"), []);
  return null;
}

import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import VisibilityIcon from "@mui/icons-material/Visibility"
import YouTubeIcon from "@mui/icons-material/YouTube"
import Toolbar from "@mui/material/Toolbar"
import { makeInterval } from "@scope/standard/timestuff/makeInterval"
import Button from "@scope/standard/ui/Button"
import Component from "@scope/standard/ui/Component"
import * as React from "react"
import { useContext } from "react"
import { ServiceMapContext } from "../../contexts/ServiceMapContext"
import { DeckTopState, DeckType } from "../../model/Deck"
import { useStore } from "../../state"
import { saveScore } from "../../tools/saveScore"

export default Component(function ({ deck }: { deck: DeckType }) {
  const store = useStore()
  const serviceMap = useContext(ServiceMapContext)!
  const showOther = async () => {
    deck.showOther()
    await saveScore(serviceMap, store)
  }
  const keep = () => {
    deck.keep(store.isFindNext)
  }
  const discard = async () => {
    deck.discard(store.isFindNext)
    await saveScore(serviceMap, store)
  }
  if (store.isSimpleInterface) {
    return (
      <Toolbar style={{ justifyContent: "space-around" }}>
        {deck.state === DeckTopState.initial && (
          <Button
            onClick={() =>
              deck.show({
                findLastTop: true,
              })
            }
          >
            <YouTubeIcon />
          </Button>
        )}
        {deck.top && (
          <>
            <Button onClick={keep} disabled={deck.state !== DeckTopState.both}>
              <CloseIcon
                style={{
                  color: deck.state === DeckTopState.both ? "red" : "#fcc",
                }}
              />
            </Button>
            <Button
              onClick={showOther}
              disabled={
                !(
                  deck.state === DeckTopState.front ||
                  deck.state === DeckTopState.back
                )
              }
            >
              <VisibilityIcon />
            </Button>
            <Button
              onClick={discard}
              disabled={deck.state !== DeckTopState.both}
            >
              <div style={{ display: "flex" }}>
                <CheckIcon
                  style={{
                    color: deck.state === DeckTopState.both ? "green" : "#cfc",
                  }}
                />
                {deck.top && makeInterval(deck.top.idlePeriod)}
              </div>
            </Button>
          </>
        )}
      </Toolbar>
    )
  }

  return (
    <Toolbar style={{ justifyContent: "space-around" }}>
      {deck.state === DeckTopState.initial && (
        <Button
          onClick={() =>
            deck.show({
              findLastTop: true,
            })
          }
        >
          <YouTubeIcon />
        </Button>
      )}
      {deck.top && (
        <>
          <Button onClick={keep} disabled={deck.state !== DeckTopState.both}>
            <CloseIcon
              style={{
                color: deck.state === DeckTopState.both ? "red" : "#fcc",
              }}
            />
            {store.isFindNext && (
              <>
                {" "}
                + <YouTubeIcon />
              </>
            )}
          </Button>
          <Button
            onClick={showOther}
            disabled={
              !(
                deck.state === DeckTopState.front ||
                deck.state === DeckTopState.back
              )
            }
          >
            <VisibilityIcon />
          </Button>
          <Button onClick={discard} disabled={deck.state !== DeckTopState.both}>
            <div style={{ flexDirection: "column" }}>
              <div style={{ display: "flex" }}>
                <CheckIcon
                  style={{
                    color: deck.state === DeckTopState.both ? "green" : "#cfc",
                  }}
                />
                {store.isFindNext && (
                  <>
                    {" "}
                    + <YouTubeIcon />
                  </>
                )}
              </div>
              <div>{deck.top && makeInterval(deck.top.idlePeriod)}</div>
            </div>
          </Button>
        </>
      )}
    </Toolbar>
  )
})

import "@scope/standard/init"
// @ts-ignore
import hljs from "highlight.js/lib/core"
// @ts-ignore
import typescript from "highlight.js/lib/languages/typescript"
import * as React from "react"
import * as ReactDOM from "react-dom/client"
import { init } from "./init"
import Main from "./main"
// hljs.registerLanguage('typescript', () => hljs.getLanguage('typescript'));
hljs.registerLanguage("typescript", typescript)
hljs.initHighlightingOnLoad()

const setUnits = () => {
  const width = window.innerWidth
  const height = window.innerHeight
  const rw = width / 64
  const ra = Math.sqrt(width * height) / 64
  document.documentElement.style.setProperty("--ra", `${ra}px`)
}

window.onresize = () => {
  setUnits()
}

setUnits()

const root2 = document.getElementById("root")!

// @ts-ignore
window.startTime = new Date()

async function main() {
  const props = await init()
  const root = ReactDOM.createRoot(root2)
  root.render(<Main {...props} />)
}

main()

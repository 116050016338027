import { TimestampModel } from "@scope/standard/model/timestamp"
import * as assert from "assert"
import { Instance, types } from "mobx-state-tree"

export enum CardState {
  initial = "initial",
  idle = "idle",
  available = "available",
}

export const editableCardProperties = {
  front: types.string,
  frontType: types.optional(
    types.enumeration(["text/plain", "text/html"]),
    "text/plain"
  ),
  back: types.string,
  summary: types.optional(types.string, ""),
  lastSeen: types.maybeNull(TimestampModel),
  idlePeriodEnd: types.maybeNull(TimestampModel),
  reverse: types.maybeNull(types.boolean),
  remark: types.optional(types.string, ""),
  parked: types.optional(types.boolean, false),
  html: types.optional(types.boolean, false),
  period: types.maybeNull(types.number),
  visible: types.optional(types.boolean, true),
  leereenheid: types.maybeNull(types.integer),
  flag: types.optional(types.boolean, false),
  highlights: types.optional(types.string, ""),
  externalId: types.maybeNull(types.string),
}
const cardProperties = {
  ...editableCardProperties,
  id: types.identifierNumber,
  parent: types.maybeNull(types.integer),
  priority: types.maybeNull(types.number),
  popularity: types.maybeNull(types.number),
  combinedPopularity: types.maybeNull(types.number),
  children: types.optional(types.integer, 0),
  subFlags: types.optional(types.integer, 0),
  created: types.optional(TimestampModel, new Date()),
  owner: types.optional(types.string, "102178486564300858544"),
}

export const CardModel = types
  .model("card_view", cardProperties)
  .views((self) => ({
    get state(): CardState {
      return self.lastSeen
        ? self.idlePeriodEnd
          ? CardState.idle
          : CardState.available
        : CardState.initial
    },
    isAncestorOf(descendant: CardType): boolean {
      return false
    },
    get idlePeriod(): number {
      return self.period === null ? 60 * 1000 : 2 * self.period
    },
  }))
  .actions((self) => ({
    setIdlePeriodEnd(): void {
      assert(self.state === CardState.available, "1")
      self.idlePeriodEnd = new Date(Date.now() + self.idlePeriod)
      // @ts-ignore
      assert(self.state === CardState.idle, "2")
    },
    wakeUp() {
      assert(self.state === CardState.idle, "3")
      if (self.reverse !== null) {
        self.reverse = !self.reverse
      }
      self.idlePeriodEnd = null
      // @ts-ignore
      assert(self.state === CardState.available, "4")
    },
    registerView(setNotSeenPeriod: boolean = false): void {
      assert(self.state !== CardState.idle, "5")
      self.idlePeriodEnd = null
      if (setNotSeenPeriod) {
        self.period = self.lastSeen ? Date.now() - +self.lastSeen : null
      }
      self.lastSeen = new Date()
      assert(self.state === CardState.available, "6")
    },
    update(updates: Partial<CardType>): void {
      Object.assign(self, updates)
    },
    setParked(parked: boolean) {
      self.parked = parked
    },
  }))
export interface CardType extends Instance<typeof CardModel> {}

export const EventModel = types.model("event", {
  time: TimestampModel,
  type: types.string,
  card: types.integer,
})
export interface EventType extends Instance<typeof EventModel> {}

import React from "react";
import { useQuery } from "react-query";
import { CardType } from "../../common/domain/Card";
import { ServiceMapContext } from "./contexts/ServiceMapContext";

export function useCrumbQuery(card: CardType["id"] | null) {
  const serviceMap = React.useContext(ServiceMapContext)!;
  async function fetch() {
    const ancestors: CardType[] = [];
    if (card) {
      let ancestor = await serviceMap.getCard(card);
      ancestors.unshift(ancestor);
      while (ancestor.parent !== null) {
        const parent = await serviceMap.getCard(ancestor.parent);
        ancestors.unshift(parent);
        ancestor = parent;
      }
    }
    return ancestors;
  }
  return useQuery(["crumb", card], fetch);
}

export function useGetCardsByParent(card: CardType, options?: any) {
  const serviceMap = React.useContext(ServiceMapContext)!;
  async function fetchFn() {
    return serviceMap.getCardsByParent(card.id);
  }
  return useQuery<CardType[]>(
    ["useGetCardsByParent", card.id],
    fetchFn,
    options
  );
}
export function useGetCardQuery(card: number | null) {
  const serviceMap = React.useContext(ServiceMapContext)!;
  async function fetchFn() {
    return card === null ? null : serviceMap.getCard(card);
  }
  return useQuery<CardType | null>(["useGetCard", card], fetchFn);
}

export function useGetAddPaths() {
  const serviceMap = React.useContext(ServiceMapContext)!;
  async function fetchFn() {
    return serviceMap.getAddPaths();
  }
  return useQuery(["useGetAddPaths"], fetchFn);
}

import FlagIcon from "@mui/icons-material/Flag";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box } from "@mui/material";
import Button from "@scope/standard/ui/Button";
import Component from "@scope/standard/ui/Component";
import React, { useState } from "react";
import { CardType } from "../../../../common/domain/Card";
import Textarea from "../../components/Textarea";
import { useService } from "../../contexts/ServiceMapContext";
import {
  useCrumbQuery,
  useGetAddPaths,
  useGetCardQuery,
} from "../../queryHooks";
import { useStore } from "../../state";

const parseCards = (text: string) => {
  const cardTexts = text
    .trim()
    .split(/\n\s*\n/)
    .map((card) => card.trim());
  const cards: any[] = [];
  let errorMessage = `${cardTexts.length} line separated texts found`;
  for (const card of cardTexts) {
    if (card) {
      const match = card.match(/(.+?)\n(.+)/s);
      if (match) {
        const [, front, back] = match;
        cards.push({ front, back });
      } else {
        errorMessage += `\nfirst ${cards.length} texts converted to valid cards\nthen followed by invalid card: \n${card}`;
        return { cards, errorMessage };
      }
    }
  }
  return { cards, errorMessage };
};

const AddCardPage = Component(() => {
  const [parked, setParked] = useState(false);
  const [reverse, setReverse] = useState<boolean | null>(null);
  const [flag, setFlag] = useState<boolean>(false);
  const [text, setText] = React.useState<string>("");
  const [message, setMessage] = React.useState<string>("");
  const [parsedCards, setParsedCards] = useState<
    { front: string; back: string }[]
  >([]);
  const store = useStore();
  const serviceMap = useService();
  const getCardQueryResult = useGetCardQuery(store.searchTop);
  const [parentCard, setParentCard] = useState<CardType["parent"]>(
    store.searchTop
  );
  const setTop = async (top: CardType | null) => {
    store.setSearchTop(top ? top.id : null);
  };
  const parse = async () => {
    await serviceMap.createCard(
      parentCard,
      parsedCards.map((card) => ({ ...card, parked, reverse, flag }))
    );
  };

  React.useEffect(() => {
    let parseResult;
    const { cards, errorMessage } = parseCards(text);
    setParsedCards(cards);
    setMessage(errorMessage);
  }, [text, setParsedCards]);

  const searchPathQuery = useCrumbQuery(store.searchTop);
  console.log(
    searchPathQuery,
    (searchPathQuery.data || []).map((ancestor) => ancestor.front).join(" > ")
  );
  const addPathsQuery = useGetAddPaths();
  return (
    <Box sx={{ background: "white", flexGrow: 1 }}>
      <table>
        <thead></thead>
        <tbody>
          <tr>
            <th>path</th>
            <td>
              <select
                value={"" + parentCard}
                onChange={(event) =>
                  setParentCard(
                    event.target.value === "null" ? null : +event.target.value
                  )
                }
              >
                <option value={"" + store.searchTop}>
                  {(searchPathQuery.data || [])
                    .map((ancestor) => ancestor.front)
                    .join(" > ")}
                </option>
                {(addPathsQuery.data || []).map(({ path, parent }) => (
                  <option key={parent} value={"" + parent}>
                    {path.join(" > ")}
                  </option>
                ))}
              </select>
            </td>
          </tr>
          <tr>
            <th>Parked</th>
            <td>
              <Button
                style={{
                  padding: 0,
                  minWidth: 0,
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  setParked(!parked);
                }}
              >
                <HighlightOffIcon
                  style={{
                    color: parked ? "red" : "black",
                  }}
                />
              </Button>
            </td>
          </tr>
          <tr>
            <th>Flag</th>
            <td>
              <Button
                style={{
                  padding: 0,
                  minWidth: 0,
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  setFlag(!flag);
                }}
              >
                <FlagIcon style={{ color: flag ? "red" : undefined }} />
              </Button>
            </td>
          </tr>
          <tr>
            <th>Reverse</th>
            <td>
              <Button
                style={{
                  padding: 0,
                  minWidth: 0,
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  setReverse(reverse ? null : reverse === null ? false : true);
                }}
              >
                {reverse
                  ? "alternately starting with back"
                  : reverse === null
                  ? "fixed front"
                  : "alternately starting with front"}
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
      <Textarea
        onChange={(event) => setText(event.target.value)}
        value={text}
        minRows={16}
        style={{ width: "100%" }}
      />
      Create {parsedCards.length} cards
      <pre>{message}</pre>
      <Button onClick={parse}>Save</Button>
    </Box>
  );
});

export default AddCardPage;

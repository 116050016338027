import { Box } from "@mui/material"
import Component from "@scope/standard/ui/Component"
import * as React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import AddCardPage from "./addcard/AddCardPage"
import Bar from "./Bar"
import Search from "./browse/Search"
import Callback from "./callback"
import DeckList from "./decks/DeckList"
import HistoryPage from "./history/HistoryPage"
import Settings from "./settings/Settings"
import Deck from "./test/Deck"

export default Component(({ registration }: any) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={"/callback"} element={<Callback />} />
        <Route
          path={"*"}
          element={
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Bar />
              {/*<KnowledgeBar/>*/}
              <Routes>
                <Route path={"/test"} element={<Deck />} />
                <Route path={"/search/:card"} element={<Search />} />
                <Route path={"/search"} element={<Search />} />
                <Route path={"/decklist"} element={<DeckList />} />
                <Route path={"/addcard"} element={<AddCardPage />} />
                <Route path={"/history"} element={<HistoryPage />} />
                <Route
                  path={"*"}
                  element={<Settings registration={registration} />}
                />
              </Routes>
            </Box>
          }
        />
      </Routes>
    </BrowserRouter>
  )
})

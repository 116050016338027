import * as assert from "assert";
import * as uuid from "uuid";

export const escapeRegExp = (text: string): string =>
  text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string

export const wait = (seconds: number) =>
  new Promise((resolve) => setTimeout(resolve, seconds * 1_000));

export const generateId = uuid.v4;

export const getUrlParameter = (name: string, documentLocation: any) => {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  var results = regex.exec(documentLocation.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1]!.replace(/\+/g, " "));
};
export const shuffle = <T>(array: T[]): T[] =>
  array
    .map<[number, T]>((card) => [Math.random(), card])
    .sort((a, b) => a[0] - b[0])
    .map((t) => t[1]);

export const pickRandom = <T>(array: T[]): T | null =>
  array.length ? array[Math.floor(array.length * Math.random())] || null : null;

export const repeat = <T>(n: number, f: (i: number) => T): T[] => {
  assert.ok(Number.isSafeInteger(n) && n >= 0, "illegal amount for repeat");
  const result: T[] = [];
  for (let i = 0; i < n; ++i) {
    result.push(f(i));
  }
  return result;
};

export const deepEqual = (a: any, b: any): boolean => {
  if (Object.is(a, b)) {
    return true;
  } else if (a instanceof Date && b instanceof Date) {
    return Object.is(+a, +b);
  } else if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length === b.length) {
      for (let i = 0; i < a.length; ++i) {
        if (!deepEqual(a[i], b[i])) {
          return false;
        }
      }
      return true;
    } else return false;
  } else if (
    typeof a === "object" &&
    a !== null &&
    typeof b === "object" &&
    b !== null
  ) {
    const entries = Object.entries(a);
    return (
      entries.length === Object.keys(b).length &&
      entries.every(([key, value]) => key in b && deepEqual(value, b[key]))
    );
  } else return false;
};

interface SetupResult<Variable, Result> {
  variable: Variable;
  dispose?: Dispose<Result>;
}

export type Dispose<Result = void> = <Result>(
  result?: Result
) => void | Promise<void>;

export function withVariable<Variable, Result>(
  setup: () =>
    | Promise<SetupResult<Variable, Result>>
    | SetupResult<Variable, Result>,
  f: (variable: Variable) => Promise<Result>
): Promise<Result>;
export function withVariable<Variable, Result>(
  setup: () =>
    | Promise<SetupResult<Variable, Result>>
    | SetupResult<Variable, Result>,
  f: (variable: Variable, done: Dispose<Result>) => Promise<void> | void
): Promise<Result>;

export async function withVariable<Variable, Result>(
  setup: () =>
    | Promise<SetupResult<Variable, Result>>
    | SetupResult<Variable, Result>,
  f: (
    variable: Variable,
    done?: Dispose<Result> | any
  ) => Promise<Result | void> | void
): Promise<Result> {
  return new Promise<Result>(async function withVariablePromiseExecutor(
    resolve,
    reject
  ) {
    const { variable, dispose } = await setup();
    let hasDoneDone = false;
    const done = async (result?: Result | any) => {
      if (!hasDoneDone) {
        if (dispose) {
          await dispose();
        }
        resolve(result);
      }
      hasDoneDone = true;
    };
    try {
      if (f.length === 2) {
        await f(variable, done);
      } else {
        const result = await f(variable);
        await done(result);
      }
    } catch (e) {
      reject(e);
    }
  });
}

import React from "react";
import { CardType } from "../../../../common/domain/Card";
import Component from "@scope/standard/ui/Component";
import CardRow from "./CardRow";
import { Box } from "@mui/material";

interface CardListProps {
  cards: CardType[];
  selected: CardType | null;
  handleClick: (card: CardType) => void;
  handleDoubleClick?: (card: CardType) => void;
}
const CardList = Component(
  ({ cards, selected, handleClick, handleDoubleClick }: CardListProps) => {
    return (
      <Box
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(9,max-content) 1fr",
        }}
      >
        {cards
          .slice()
          .sort((a, b) => a.front.localeCompare(b.front))
          .map((card) => (
            <CardRow
              key={card.id}
              card={card}
              selected={selected}
              handleClick={handleClick}
              handleDoubleClick={handleDoubleClick}
            />
          ))}
      </Box>
    );
  }
);

export default CardList;

import { deepEqual } from "@scope/standard/utils"
import { applySnapshot, getSnapshot, Instance, types } from "mobx-state-tree"
import {
  CardModel,
  CardState,
  CardType,
  editableCardProperties,
  EventModel,
} from "../../../common/domain/Card"
import { CardId, CardUpdate } from "../../../common/domain/ServiceMap"
import { DeckModel, DeckTopState, DeckType } from "./Deck"

let timer: any

function truncateCards(cards: CardType[]): CardType[] {
  const initialFlashcards: CardType[] = []
  const availableFlashcards: CardType[] = []
  const idleFlashcards: CardType[] = []
  for (const flashcard of cards) {
    if (flashcard.lastSeen) {
      if (flashcard.idlePeriodEnd) {
        idleFlashcards.push(flashcard)
      } else {
        availableFlashcards.push(flashcard)
      }
    } else {
      initialFlashcards.push(flashcard)
    }
  }
  const sortedInitialFlashcards = initialFlashcards.sort((a, b) => {
    if (a.combinedPopularity !== null && b.combinedPopularity !== null) {
      return a.combinedPopularity - b.combinedPopularity
    } else if (a.combinedPopularity !== null && b.combinedPopularity === null) {
      return 1
    } else if (a.combinedPopularity === null && b.combinedPopularity !== null) {
      return -1
    } else if (a.priority !== null && b.priority !== null) {
      return a.priority - b.priority
    } else if (a.priority !== null && b.priority === null) {
      return 1
    } else if (a.priority === null && b.priority !== null) {
      return -1
    } else return 0
  })
  const selectedFlashcards = [
    ...idleFlashcards,
    ...availableFlashcards,
    ...sortedInitialFlashcards.slice(-256),
  ]
  return selectedFlashcards
}

export const StoreModel = types
  .model({
    cards: types.array(types.late(() => CardModel)),
    decks: types.array(types.late(() => DeckModel)),
    deck: types.maybeNull(types.reference(types.late(() => DeckModel))),
    autoUpload: types.optional(types.boolean, false),
    isFindNext: types.optional(types.boolean, true),
    isSaving: types.optional(types.boolean, false),
    online: types.optional(types.boolean, true),
    jwt: types.optional(types.maybeNull(types.string), null),
    fontSize: types.optional(types.number, 16),
    iconSize: types.optional(types.number, 24),
    dirtyProperties: types.map(
      types.array(types.enumeration(Object.keys(editableCardProperties)))
    ),
    events: types.array(EventModel),
    searchTop: types.maybeNull(types.integer),
    openCards: types.array(types.integer),
    isSimpleInterface: types.optional(types.boolean, true),
  })
  .views((self) => ({
    get cardMap() {
      return new Map(self.cards.map((c) => [c.id, c]))
    },
  }))
  .views((self) => ({
    get dirtyCards(): CardType[] {
      return self.cards.filter((f) => self.dirtyProperties.has("" + f.id))
    },
    get idleCards(): CardType[] {
      return self.cards
        .filter((f) => f.state === CardState.idle)
        .sort(
          (a, b) =>
            (a.idlePeriodEnd ? +a.idlePeriodEnd : 0) -
            (b.idlePeriodEnd ? +b.idlePeriodEnd : 0)
        )
    },
    getPath(card: CardType, until?: CardId): string {
      const path: string[] = []
      let parentId: CardId | null = card.parent
      while (parentId && (!until || parentId !== until)) {
        const parent: CardType | undefined = self.cardMap.get(parentId)
        if (parent) {
          path.push(parent.front)
        } else {
          break
        }
        parentId = parent.parent
      }
      return path.join("/")
    },
  }))
  .views((self) => ({
    get dirtyScores(): CardUpdate[] {
      return self.dirtyCards
        .reduce<[CardType, string[]][]>((result, card) => {
          if (self.dirtyProperties.has("" + card.id)) {
            return [...result, [card, self.dirtyProperties.get("" + card.id)!]]
          } else return result
        }, [])
        .map(
          ([card, dirtyProperties]) =>
            ({
              id: card.id,
              ...Object.fromEntries(
                Object.entries(getSnapshot(card)).filter(([key]) =>
                  dirtyProperties.includes(key)
                )
              ),
            } as CardUpdate)
        )
    },
    get isDirty(): boolean {
      return !!self.dirtyCards.length
    },
  }))
  .actions((self) => ({
    cleanCard(card: CardType): void {
      self.dirtyProperties.delete("" + card.id)
    },
  }))
  .actions((self) => {
    return {
      checkIdleCards() {
        self.idleCards.every((card) => {
          const due = +card.idlePeriodEnd! < Date.now()
          if (due) {
            card.wakeUp()
          }
          return due
        })
        const nextToWakeUp = self.idleCards[0]
        if (nextToWakeUp) {
          const timeout = Math.min(
            24 * 60 * 60 * 1000,
            Math.max(0, +nextToWakeUp.idlePeriodEnd! - Date.now() + 100)
          )
          timer = setTimeout(this.checkIdleCards, timeout)
        } else {
          clearTimeout(timer)
        }
      },
      deleteDeck(deck: DeckType) {
        const deckToBeDeleted = deck
        deckToBeDeleted.setTop(null)
        deckToBeDeleted.cards.replace([])
        if (deck === self.deck) self.deck = null
        self.decks.replace(
          self.decks.filter((deck) => deck !== deckToBeDeleted)
        )
        self.cards.replace([
          ...self.decks.reduce(
            (r, d) => (d.cards.forEach((f: any) => r.add(f)), r),
            new Set<CardType>()
          ),
        ])
        for (const key of self.dirtyProperties.keys()) {
          if (!self.cardMap.has(+key)) {
            self.dirtyProperties.delete(key)
          }
        }
      },
      refreshDeck(deck: DeckType, cards: CardType[]) {
        deck.setTop(null)
        const selectedCards = truncateCards(cards)
        for (const card of selectedCards) {
          const existingCard = self.cardMap.get(card.id)
          if (existingCard) {
            applySnapshot(existingCard, card)
          } else {
            self.cards.push(card)
          }
        }
        deck.setFlashcards(selectedCards)
      },
      addCard(card: CardType) {
        self.cards.push(card)
      },
      setIsFindNext(isFindNext: boolean) {
        self.isFindNext = isFindNext
      },
      updateScores(scores: CardUpdate[]) {
        const oldTops: [DeckType, CardType | null][] = self.decks.map(
          (deck) => [deck, deck.top]
        )
        for (const score of scores) {
          const { id, ...rest } = score
          const flashCard = self.cardMap.get(id)
          if (flashCard) {
            const oldCard = getSnapshot(flashCard)
            const newCard = { ...oldCard, ...rest }
            const clean = deepEqual(oldCard, newCard)
            if (clean) {
              self.cleanCard(flashCard)
            } else {
              console.error("not clean")
              console.error("flashCard", flashCard)
              console.error("oldCard", oldCard)
              console.error("newCard", newCard)
            }
          }
        }
        for (const [deck, oldTop] of oldTops) {
          if (deck.top) {
            if (deck.top !== oldTop) {
              if (self.deck) {
                self.deck.state = DeckTopState.initial
              }
              deck.setTop(null)
            }
          } else {
          }
        }
      },
      setSelectedDeck(deck: DeckType) {
        self.deck = deck
      },
      setAutoUpload(autoUpload: boolean) {
        self.autoUpload = autoUpload
      },
      setOnline(connect: boolean) {
        self.online = connect
      },
      setJwt(jwt: string | null) {
        self.jwt = jwt
      },
    }
  })
  .actions((self) => ({
    createDeck(id: number, parentId: CardId, name: string) {
      const deck = DeckModel.create({
        id,
        parentId,
        name,
        state: DeckTopState.initial,
        findInNew: true,
      })
      self.decks.push(deck)
      self.deck = deck
      return deck
    },
    deleteAll() {
      self.deck = null
      self.decks.length = 0
      self.cards.length = 0
      self.dirtyProperties.clear()
      self.events.clear()
    },
    setFontSize(fontSize: number) {
      self.fontSize = fontSize
    },
    setIconSize(iconSize: number) {
      self.iconSize = iconSize
    },
    addDirtyProperty(cardId: CardId, property: string) {
      const dirtyProperties: any[] | void = self.dirtyProperties.get(
        "" + cardId
      )
      if (!dirtyProperties) {
        self.dirtyProperties.set("" + cardId, [property as any])
      } else {
        if (!dirtyProperties.includes(property)) {
          dirtyProperties.push(property)
        }
      }
    },
    addEvent(card: CardType, type: string) {
      self.events.push({
        card: card.id,
        type,
        time: new Date(),
      })
    },
    setSearchTop(card: number | null) {
      self.searchTop = card
    },
    openCard(card: number) {
      if (!self.openCards.includes(card)) {
        self.openCards.push(card)
      }
    },
    closeCard(card: number) {
      if (self.openCards.includes(card)) {
        self.openCards.remove(card)
      }
    },
    setIsSimpleInterface(v: boolean) {
      self.isSimpleInterface = v
    },
  }))

export interface StoreType extends Instance<typeof StoreModel> {}

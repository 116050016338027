import { experimental_extendTheme as extendTheme } from "@mui/material/styles";

export default (fontSize: number, iconSize: number) =>
  extendTheme({
    breakpoints: {
      values: { xs: 1e6, sm: 1e6, md: 1e6, lg: 1e6, xl: 1e6 },
    },
    typography: {
      fontSize,
      fontFamily: "Helvetica Neue, Arial, sans-serif",
    },
    components: {
      MuiButton: {
        defaultProps: {
          variant: "outlined",
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            margin: 0,
            padding: 0,
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: iconSize,
          },
          fontSizeLarge: {
            fontSize: iconSize * 1.5,
          },
        },
      },
    },
  });

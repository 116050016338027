import AutorenewIcon from "@mui/icons-material/Autorenew"
import BorderColorIcon from "@mui/icons-material/BorderColor"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import EditIcon from "@mui/icons-material/Edit"
import FlagIcon from "@mui/icons-material/Flag"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import VolumeUpIcon from "@mui/icons-material/VolumeUp"
import YouTubeIcon from "@mui/icons-material/YouTube"
import { Toolbar } from "@mui/material"
import { BadgeProps } from "@mui/material/Badge"
import Button from "@scope/standard/ui/Button"
import Component from "@scope/standard/ui/Component"
import * as React from "react"
import { useContext } from "react"
import { ServerServiceMap } from "../../../../server/src/serviceMap"
import Badge from "../../components/Badge"
import Checkbox from "../../components/Checkbox"
import { ClientContext } from "../../contexts/ClientContext"
import { useService } from "../../contexts/ServiceMapContext"
import { DeckType } from "../../model/Deck"
import { StoreType } from "../../model/Store"
import { useStore } from "../../state"
import { saveScore } from "../../tools/saveScore"
export const save = async (serviceMap: ServerServiceMap, store: StoreType) => {
  await saveScore(serviceMap, store, true)
}
const MyBadge: React.FunctionComponent<BadgeProps> = (props) => (
  <Badge color="primary" max={1e6} {...props} />
)

export default Component(function ({
  deck,
  toggleEdit,
  newSelection,
}: {
  deck: DeckType
  toggleEdit: () => void
  newSelection: null | [number, number]
}) {
  const store = useStore()
  const client = useContext(ClientContext)!
  const serviceMap = useService()
  const handleFindNext = (event: any) => {
    store.setIsFindNext(event.target.checked)
  }
  const play = () => {
    if (deck.top) {
      const utterance = new SpeechSynthesisUtterance()
      utterance.text = deck.top.front
      utterance.lang = "pl-PL"
      speechSynthesis.speak(utterance)
    }
  }
  const handleEditClick = () => {
    toggleEdit()
  }

  return (
    <Toolbar style={{ justifyContent: "space-around", overflow: "auto" }}>
      {!store.isSimpleInterface && (
        <>
          <Checkbox onChange={handleFindNext} checked={store.isFindNext}>
            <YouTubeIcon />
            <AutorenewIcon />
          </Checkbox>

          <Button onClick={play} disabled={!deck.top}>
            <VolumeUpIcon />
          </Button>
          <Button
            disabled={!deck.top}
            onClick={() => {
              if (deck.top) {
                deck.top.setParked(true)
                deck.setTop(null)
              }
            }}
          >
            <HighlightOffIcon />
          </Button>

          <Button
            disabled={!deck.top || !newSelection}
            onClick={() => {
              if (deck.top && newSelection) {
                const highlights = JSON.parse(deck.top.highlights)
                const newSelections: [number, number][] = []
                for (const [a, b] of highlights) {
                  const isBefore = a > newSelection[1]
                  const isAfter = b < newSelection[0]
                  const overlap = !isAfter && !isBefore
                  if (overlap) {
                    newSelection[0] = Math.min(newSelection[0], a)
                    newSelection[1] = Math.max(newSelection[1], b)
                  } else {
                    newSelections.push([a, b])
                  }
                }
                deck.top.update({
                  highlights: JSON.stringify(
                    [...newSelections, newSelection].sort((a, b) => a[0] - b[0])
                  ),
                })

                getSelection()?.removeAllRanges()
              }
            }}
          >
            <MyBadge
              badgeContent={
                deck.top ? JSON.parse(deck.top.highlights).length : 0
              }
            >
              <BorderColorIcon style={{ color: "orange" }} />
            </MyBadge>
          </Button>
          <Button
            disabled={!deck.top || !JSON.parse(deck.top.highlights).length}
            onClick={() => {
              if (deck.top && confirm("Delete all highlights?")) {
                deck.top.update({
                  highlights: JSON.stringify([]),
                })
              }
            }}
          >
            <BorderColorIcon style={{ color: "orange" }} />
            <DeleteForeverIcon />
          </Button>
          <Button
            disabled={!deck.top}
            onClick={() => {
              if (deck.top) {
                const flag = !deck.top.flag
                const remark = prompt(
                  `flag: ${flag}, remark: `,
                  deck.top.remark
                )
                if (typeof remark === "string")
                  deck.top.update({
                    flag,
                    remark,
                  })
              }
            }}
          >
            <FlagIcon style={{ color: deck.top?.flag ? "red" : undefined }} />
          </Button>
          <Button onClick={handleEditClick} disabled={!deck.top}>
            <EditIcon />
          </Button>
        </>
      )}
      <Button
        onClick={() => void save(serviceMap, store)}
        disabled={!store.dirtyCards.length}
      >
        <MyBadge badgeContent={store.dirtyCards.length}>
          <CloudUploadIcon
            style={{ color: client.connected ? "inherit" : "red" }}
          />
        </MyBadge>
      </Button>
    </Toolbar>
  )
})

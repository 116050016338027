import Client from "@scope/standard/io/Client"
import { Jsoncoder } from "@scope/standard/jsoncoder/jsoncoder"
import { autorun } from "mobx"
import { getSnapshot } from "mobx-state-tree"
import { CardModel } from "../../common/domain/Card"
import { DeckModel } from "../../common/domain/Deck"
import { ClientServiceMap } from "../../desktop/src/client/serviceMap"
import { ServerServiceMap } from "../../server/src/serviceMap"
import { ClientConfigModel } from "./config"
import { initState } from "./state"
import { saveScore } from "./tools/saveScore"

export async function init() {
  let registration
  try {
    await new Promise<void>((resolve) =>
      window.addEventListener("load", async () => {
        try {
          registration = await navigator.serviceWorker.register(
            "/serviceworker.js",
            { type: "module" }
          )
          console.log("SW registered: ", registration)
        } catch (e) {
          console.error(e)
        }
        resolve()
      })
    )
  } catch (e) {
    console.error(e)
  }
  const store = await initState()

  const config = getSnapshot(
    ClientConfigModel.create({
      ...(process.env.CONFIG as any),
      redirectUri: `${window.location.origin}/callback`,
    })
  )

  const client = new Client<ServerServiceMap, ClientServiceMap>(
    config.dataUri,
    {},
    false
  )
  client.jsonCoder = new Jsoncoder(new Map(), {}, [CardModel, DeckModel])
  client.getJwt = () => store.jwt || ""
  autorun(() => client.connected && saveScore(serviceMap, store))
  autorun(() => {
    if (store.online && store.jwt) {
      console.log("connect", store.online, store.jwt)
      client.connect()
    } else {
      console.log("disconnect")
      client.disconnect()
    }
  })
  const serviceMap = client.serverServiceMap

  return { client, config, store, serviceMap, registration }
}

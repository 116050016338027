import { Box, Card, CardContent, Paper } from "@mui/material"
import Component from "@scope/standard/ui/Component"
// @ts-ignore
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import hljs from "highlight.js/lib/core"
import "highlight.js/styles/github.css"
import * as React from "react"
import { useContext, useEffect, useRef } from "react"
import { CardType } from "../../../../common/domain/Card"
import Textarea from "../../components/Textarea"
import { ServiceMapContext } from "../../contexts/ServiceMapContext"
import { DeckTopState } from "../../model/Deck"
import { useStore } from "../../state"
import { saveScore } from "../../tools/saveScore"

hljs.configure({ languages: ["typescript"] })

interface Props {
  flashcard: CardType
  topState: DeckTopState
  handleClick: () => void
  edit: boolean
  path: string
  setNewSelection: (selection: null | [number, number]) => void
}

function getTextBefore(
  topNode: Node,
  endNode: Node
): { text: string; endNodeFound: boolean } {
  let text: string = ""
  let endNodeFound = false
  for (const node of Array.from(topNode.childNodes)) {
    endNodeFound = node === endNode

    if (!endNodeFound) {
      // @ts-ignore
      if (node.data) {
        // @ts-ignore
        text += node.data
      } else {
        const result = getTextBefore(node, endNode)
        text += result.text
        endNodeFound = result.endNodeFound
      }
    }
    if (endNodeFound) {
      break
    }
  }
  return { text, endNodeFound }
}

function Selected({
  text,
  selections,
  setNewSelection,
}: {
  text: string
  selections: [number, number][]
  setNewSelection: (selection: null | [number, number]) => void
}) {
  const pieces: React.ReactNode[] = []
  let used = 0
  for (const selection of selections) {
    const [begin, end] = selection
    pieces.push(text.slice(used, begin))
    pieces.push(
      <span key={"selection" + begin} style={{ background: "orange" }}>
        {text.slice(begin, end)}
      </span>
    )
    used = end
  }
  React.useEffect(() => {
    const f = () => {
      const selection = window.getSelection()
      let newSelection: [number, number] | null = null
      if (selection) {
        console.log(selection)
        const anchorNode = selection.anchorNode
        if (anchorNode) {
          const topNode = document.querySelector(".textselect")!
          const { text } = getTextBefore(topNode, anchorNode)
          const anchorOffset: number = text.length + selection.anchorOffset
          const focusNode = selection.focusNode
          if (focusNode) {
            const { text } = getTextBefore(topNode, focusNode)
            const focusOffset: number = text.length + selection.focusOffset
            if (anchorOffset !== focusOffset) {
              newSelection = [anchorOffset, focusOffset].sort(
                (a, b) => a - b
              ) as [number, number]
              setNewSelection(newSelection)
            }
          }
        }
      }
    }
    document.addEventListener("selectionchange", f)
    return () => document.removeEventListener("selectionchange", f)
  }, [])
  pieces.push(text.slice(used))
  return <div className={"textselect"}>{pieces}</div>
}

export default Component(
  ({
    topState,
    flashcard,
    handleClick,
    edit,
    path,
    setNewSelection,
  }: Props) => {
    const [onTop, setOnTop] = React.useState<boolean>(false)
    const [onBottom, setOnBottom] = React.useState<boolean>(false)
    const [scrollTop, setScrollTop] = React.useState<number>(0)
    const serviceMap = useContext(ServiceMapContext)!
    const store = useStore()
    const backRef = useRef<any | null>(null)

    useEffect(() => {
      hljs.initHighlighting()
      const backElement = backRef.current
      if (backElement) {
        const nodes = backElement.querySelectorAll("code")
        console.log("nodes: ", nodes)
        for (const node of nodes) {
          hljs.highlightBlock(node)
          if (!node.classList.contains("language-ts")) {
            node.style.display = "unset"
            node.style.padding = "unset"
          } else {
            // node.style.fontSize='8px'
          }
          node.style.fontFamily = "Helvetica Neue"
          node.style.background = "#eee"
        }
      }
    }, [flashcard.back, edit])

    const ref = useRef<any>()

    useEffect(() => {
      setOnTop(scrollTop <= 0)
      setOnBottom(
        ref.current.clientHeight + scrollTop >= ref.current.scrollHeight
      )
    }, [scrollTop])
    const handleChange = (
      property: keyof CardType,
      event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
      flashcard.update({ [property]: event.target.value })
      saveScore(serviceMap, store)
    }
    const handleFrontChange = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
      handleChange("front", event)
    const handleSummaryChange = (
      event: React.ChangeEvent<HTMLTextAreaElement>
    ) => handleChange("summary", event)
    const handleBackChange = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
      handleChange("back", event)
    const handleRemarkChange = (
      event: React.ChangeEvent<HTMLTextAreaElement>
    ) => handleChange("remark", event)
    console.log(ref.current?.clientHeight + scrollTop)
    return (
      <Box
        sx={{
          flexGrow: 1,
          overflow: "hidden",
          flexBasis: 0,
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            boxSizing: "border-box",
            top: 0,
            display: onTop ? "none" : undefined,
            background: "rgba(255,255,255,.75)",
            width: "100%",
            textAlign: "center",
          }}
          onClick={() => (ref.current.scrollTop -= 32)}
        >
          <KeyboardArrowUpIcon fontSize={"large"} />
        </Box>
        <Box
          sx={{
            position: "absolute",
            boxSizing: "border-box",
            bottom: 0,
            display: onBottom ? "none" : undefined,
            background: "rgba(255,255,255,.75)",
            width: "100%",
            textAlign: "center",
          }}
          onClick={() => (ref.current.scrollTop += 32)}
        >
          <KeyboardArrowDownIcon fontSize={"large"} />
        </Box>
        <Card
          ref={ref}
          sx={{
            padding: 0,
            transition: "transform 0s",
            "& > code": {
              whiteSpace: "nowrap",
            },
            overflow: "auto",
            height: "100%",
          }}
          onClick={handleClick}
          onScroll={(event) => {
            setScrollTop(event.currentTarget.scrollTop)
          }}
        >
          <CardContent style={{ overflowWrap: "break-word", padding: 0 }}>
            <Paper
              style={{
                padding: 4,
                display: [DeckTopState.front, DeckTopState.both].includes(
                  topState
                )
                  ? undefined
                  : "none",
              }}
            >
              {!!path && <div style={{ color: "#ccc" }}>{path}</div>}
              {edit && (
                <Textarea
                  style={{ width: "100%", minHeight: "8em" }}
                  onBlur={handleFrontChange}
                  value={flashcard.front}
                />
              )}
              {!edit &&
                (flashcard.frontType === "text/html" ? (
                  <div dangerouslySetInnerHTML={{ __html: flashcard.front }} />
                ) : (
                  flashcard.front
                ))}
            </Paper>
            <Paper>
              {edit && (
                <Textarea
                  style={{ width: "100%", minHeight: "8em" }}
                  onBlur={handleRemarkChange}
                  value={flashcard.remark}
                />
              )}
              {!edit && flashcard.remark}
            </Paper>
            <Paper
              style={{
                background: "#ccc",
                padding: 4,
                whiteSpace: "pre-wrap",
                display: [DeckTopState.back, DeckTopState.both].includes(
                  topState
                )
                  ? undefined
                  : "none",
              }}
            >
              {edit && (
                <Textarea
                  style={{ width: "100%", minHeight: "8em" }}
                  onBlur={handleSummaryChange}
                  value={flashcard.summary || ""}
                />
              )}
              {!edit && flashcard.summary}
            </Paper>
            <Paper
              style={{
                padding: 4,
                display: [DeckTopState.back, DeckTopState.both].includes(
                  topState
                )
                  ? undefined
                  : "none",
                overflow: "auto",
              }}
            >
              {edit && (
                <Textarea
                  style={{ width: "100%", minHeight: "8em" }}
                  onBlur={handleBackChange}
                  value={flashcard.back || ""}
                />
              )}
              {!edit &&
                (flashcard.html ? (
                  <div
                    ref={backRef}
                    dangerouslySetInnerHTML={{ __html: flashcard.back }}
                  />
                ) : (
                  <div style={{ whiteSpace: "pre-wrap" }}>
                    <Selected
                      text={flashcard.back}
                      selections={JSON.parse(flashcard.highlights)}
                      setNewSelection={setNewSelection}
                    />
                  </div>
                ))}
            </Paper>
          </CardContent>
        </Card>
      </Box>
    )
  }
)

import AddIcon from "@mui/icons-material/Add"
import ArrowDownward from "@mui/icons-material/ArrowDownward"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import SubscriptionsIcon from "@mui/icons-material/Subscriptions"
import { CardType } from "../../../../common/domain/Card"
import CardPath from "./CardPath"
import Textarea from "../../components/Textarea"
import MassInput from "./MassInput"
import React, { useContext, useEffect, useState } from "react"
import { Box, Container } from "@mui/material"
import Button from "@scope/standard/ui/Button"
import { useStore } from "../../state"
import { ServiceMapContext } from "../../contexts/ServiceMapContext"
import { installDeck } from "../decks/DeckList"

function CardEdit(props: {
  activeCard: CardType | null
  setTop: (card: CardType | null) => void
  setSelectedCard: (card: CardType | null) => void
  handleCreate: any
  handleDelete: any
  handleLoad: any
}) {
  const serviceMap = useContext(ServiceMapContext)!
  const store = useStore()

  const handleGetChildren = async () => {
    if (props.activeCard) {
      props.setTop(props.activeCard)
    }
  }
  return (
    <>
      <Box sx={{ display: "flex" }}>
        Active card:{" "}
        <CardPath card={props.activeCard?.id ?? null} setTop={props.setTop} />
      </Box>
      <div>
        <Button disabled={!props.activeCard} onClick={props.handleLoad}>
          <SubscriptionsIcon />
          <AddIcon />
        </Button>
        <Button disabled={!props.activeCard} onClick={handleGetChildren}>
          <ArrowDownward />
        </Button>
        <Button onClick={props.handleCreate}>+</Button>
        <Button disabled={!props.activeCard} onClick={props.handleDelete}>
          <DeleteForeverIcon />
        </Button>
      </div>
      <Container>
        <Textarea
          style={{ display: "block", minWidth: "32em" }}
          disabled={!props.activeCard}
          value={props.activeCard?.front ?? "<front>"}
          onChange={(event) =>
            props.activeCard?.update({ front: event.target.value })
          }
        />
        <Textarea
          style={{ display: "block", minWidth: "32em" }}
          disabled={!props.activeCard}
          value={props.activeCard?.back ?? "<back>"}
          onChange={(event) =>
            props.activeCard?.update({ back: event.target.value })
          }
        />
        <Button
          disabled={!props.activeCard}
          onClick={() =>
            props.activeCard &&
            serviceMap.saveScore([
              {
                id: props.activeCard.id,
                front: props.activeCard.front,
                back: props.activeCard.back,
              },
            ])
          }
        >
          Save
        </Button>
      </Container>
      {
        <div>
          <MassInput
            card={props.activeCard}
            handleGetChildren={handleGetChildren}
          />
        </div>
      }
    </>
  )
}

export default CardEdit

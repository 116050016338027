import React from "react"
import Component from "@scope/standard/ui/Component"
import { CardType } from "../../../../common/domain/Card"
import { useService } from "../../contexts/ServiceMapContext"
import CardList from "./CardList"
import Button from "@scope/standard/ui/Button"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import { Box } from "@mui/material"
import FlagIcon from "@mui/icons-material/Flag"
import { useGetCardsByParent } from "../../queryHooks"

const CardRow = Component(function ({
  card,
  selected,
  handleClick,
  handleDoubleClick,
}: {
  card: CardType
  selected: CardType | null
  handleClick: any
  handleDoubleClick: any
}) {
  const [open, setOpen] = React.useState(false)
  const service = useService()
  const {
    data: cards,
    refetch,
    isFetching,
  } = useGetCardsByParent(card, {
    enabled: false,
  })
  React.useEffect(() => {
    if (open) refetch()
  }, [open])
  async function setParked(card: CardType, parked: boolean) {
    await service.setParked(card.id, parked)
    card.setParked(parked)
  }
  async function rotateReverse(card: CardType) {
    const reverse: null | boolean = card.reverse
      ? null
      : card.reverse === null
      ? false
      : true
    await service.saveScore([{ id: card.id, reverse }])
    card.update({ reverse })
  }
  async function setFlag(card: CardType, enabled: boolean) {
    await service.saveScore([{ id: card.id, flag: enabled }])
    card.update({ flag: enabled })
  }
  function expand() {
    setOpen(!open)
  }
  return (
    <Box
      sx={{
        display: "contents",
        "& > *": {
          background: selected && selected.id === card.id ? "#ccf" : "#fff",
        },
      }}
    >
      <Button
        style={{
          padding: 0,
          minWidth: 0,
        }}
        onClick={(event) => {
          event.stopPropagation()
          setParked(card, !card.parked)
        }}
      >
        <HighlightOffIcon
          style={{
            color: card.parked ? "red" : "black",
          }}
        />
      </Button>
      <Box sx={{ textAlign: "right" }}>{card.id}</Box>
      <Button
        style={{
          padding: 0,
          minWidth: 0,
        }}
        onClick={(event) => {
          event.stopPropagation()
          setFlag(card, !card.flag)
        }}
      >
        <FlagIcon style={{ color: card.flag ? "red" : undefined }} />
      </Button>
      <Box
        style={{
          display: "inline-block",
          width: "2em",
          textAlign: "right",
          margin: "0 1em",
        }}
      >
        {card.subFlags}
      </Box>{" "}
      <Button
        style={{
          padding: 0,
          minWidth: 0,
        }}
        onClick={(event) => {
          event.stopPropagation()
          rotateReverse(card)
        }}
      >
        {card.reverse ? "back" : card.reverse === null ? "fixed" : "front"}
      </Button>
      <Box
        style={{
          display: "inline-block",
          width: "2em",
          textAlign: "right",
          margin: "0 1em",
        }}
      >
        {card.children}
      </Box>{" "}
      <Button
        disabled={!card.children}
        style={{
          padding: 0,
          minWidth: 0,
        }}
        onClick={(event) => {
          event.stopPropagation()
          expand()
        }}
      >
        +
      </Button>
      <Box
        onClick={(event) => {
          event.stopPropagation()
          handleClick(card)
        }}
      >
        {card.front}
      </Box>
      <Box>{card.back}</Box>
      <Box style={{ gridColumn: "1 / -1", padding: "0 0 0 1em" }}>
        {open &&
          (isFetching ? (
            "loading"
          ) : (
            <CardList
              cards={cards || []}
              selected={selected}
              handleClick={handleClick}
              handleDoubleClick={handleDoubleClick}
            />
          ))}
      </Box>
    </Box>
  )
})

export default CardRow

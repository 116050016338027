import { CardUpdate } from "../../../common/domain/ServiceMap";
import { StoreType } from "../model/Store";
import { ServerServiceMap } from "../../../server/src/serviceMap";

export const saveScore = async (
  serviceMap: ServerServiceMap,
  store: StoreType,
  manual: boolean = false
) => {
  if (manual || store.autoUpload) {
    let scores = [...store.dirtyScores];
    while (scores.length) {
      const pageSize = 10;
      const page = scores.slice(0, pageSize);
      scores = scores.slice(pageSize);
      const updates: CardUpdate[] = await serviceMap.saveScore(page);
      store.updateScores(updates);
    }
    let events = [...store.events];
    while (events.length) {
      const pageSize = 10;
      const page = events.slice(0, pageSize);
      await serviceMap.saveEvents(page);
      events = events.slice(pageSize);
    }
  }
};

import Component from "@scope/standard/ui/Component"
import * as React from "react"
import { useState } from "react"
import Container from "../../components/Container"
import { DeckTopState } from "../../model/Deck"
import { useStore } from "../../state"
import Flashcard from "./Flashcard"
import Footer from "./Footer"
import Header from "./Header"
import State from "./State"
import TestBar from "./TestBar"

export default Component(function () {
  const [edit, setEdit] = useState<boolean>(false)
  const store = useStore()
  const deck = store.deck
  if (!deck) return <div>create a new deck</div>

  function handleClick() {
    if (deck && [DeckTopState.front, DeckTopState.back].includes(deck.state)) {
      deck.showOther()
    }
  }

  function toggleEdit() {
    setEdit(!edit)
  }

  const path = deck.top ? store.getPath(deck.top, deck.parentId) : ""
  const [newSelection, setNewSelection] = React.useState<
    null | [number, number]
  >(null)
  return (
    <Container sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
      <Header deck={deck} />
      <State deck={deck} />
      <TestBar deck={deck} />
      {deck.top && (
        <Flashcard
          topState={deck.state}
          flashcard={deck.top}
          handleClick={handleClick}
          edit={edit}
          path={path}
          setNewSelection={setNewSelection}
        />
      )}
      {!deck.top && <div style={{ flexGrow: 1 }} />}
      <Footer deck={deck} toggleEdit={toggleEdit} newSelection={newSelection} />
    </Container>
  )
})

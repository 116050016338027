import { Instance, types } from "mobx-state-tree";

export const TimestampModel = types.custom<string | number, Date>({
  name: "timestamp",
  fromSnapshot(value: string | number) {
    return new Date(value);
  },
  toSnapshot(value: Date) {
    return value.toISOString();
  },
  isTargetType(value: string | number | Date): boolean {
    return value instanceof Date;
  },
  getValidationMessage(value: string | number): string {
    if (typeof value === "number") {
      if (Number.isSafeInteger(value)) {
        return "";
      }
    } else if (typeof value === "string") {
      if (/^\d\d\d\d-\d\d-\d\dT/.test(value)) {
        return "";
      }
    }
    return `'${value}' doesn't look like a valid date`;
  },
});

export interface TimestampType extends Instance<typeof TimestampModel> {}

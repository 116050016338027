import {
  Badge,
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@mui/material";
import { BadgeProps } from "@mui/material/Badge";
import { observer } from "mobx-react";
import * as React from "react";

export default observer((args: BadgeProps) => {
  const theme = useTheme();
  return (
    <Badge
      max={1e6}
      {...args}
      sx={{
        margin: {
          margin: theme.spacing(2),
        },
        padding: {
          padding: theme.spacing(0, 2),
        },
      }}
    />
  );
});

import FlareIcon from "@mui/icons-material/Flare"
import HotelIcon from "@mui/icons-material/Hotel"
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer"
import { Box } from "@mui/material"
import Toolbar from "@mui/material/Toolbar"
import Button from "@scope/standard/ui/Button"
import Component from "@scope/standard/ui/Component"
import * as React from "react"
import Badge from "../../components/Badge"
import { DeckType } from "../../model/Deck"
import { useStore } from "../../state"

export default Component(function ({ deck }: { deck: DeckType }) {
  const store = useStore()
  function show(
    findInAvailable?: boolean,
    findInNew?: boolean,
    findInIdle?: boolean
  ) {
    deck.show({
      findLastTop: true,
      findInAvailable,
      findInNew,
      findInIdle,
    })
  }
  if (store.isSimpleInterface) {
    return (
      <Box>
        {!!deck.cardsByState.initial.length && (
          <>{deck.cardsByState.initial.length} never seen cards, </>
        )}
        {deck.cardsByState.available.length} available cards,
        {!!deck.cardsByState.idle.length && (
          <>
            {deck.cardsByState.idle.length} idle cards, next card becomes
            available: {deck.timeoutString}
          </>
        )}
      </Box>
    )
  }
  return (
    <Toolbar style={{ justifyContent: "space-around" }}>
      <Button
        disabled={!deck.cardsByState.initial.length}
        onClick={() => show(false, true, false)}
      >
        <Badge
          color="primary"
          max={1e6}
          badgeContent={deck.cardsByState.initial.length}
        >
          <FlareIcon fontSize={"large"} />
        </Badge>
      </Button>
      <Button
        disabled={!deck.cardsByState.available.length}
        onClick={() => show(true, false, false)}
      >
        <Badge
          color="primary"
          max={1e6}
          badgeContent={deck.cardsByState.available.length}
        >
          <QuestionAnswerIcon fontSize={"large"} />
        </Badge>
      </Button>
      <Button
        disabled={!deck.cardsByState.idle.length}
        onClick={() => show(false, false, true)}
      >
        <Badge
          color="primary"
          max={1e6}
          badgeContent={deck.cardsByState.idle.length}
        >
          <HotelIcon fontSize={"large"} />
        </Badge>
      </Button>
    </Toolbar>
  )
})

import AddIcon from "@mui/icons-material/Add"
import ArrowDownward from "@mui/icons-material/ArrowDownward"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import SubscriptionsIcon from "@mui/icons-material/Subscriptions"
import { Container } from "@mui/material"
import Button from "@scope/standard/ui/Button"
import Component from "@scope/standard/ui/Component"
import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { CardType } from "../../../../common/domain/Card"
import Textarea from "../../components/Textarea"
import { ClientContext } from "../../contexts/ClientContext"
import { ServiceMapContext } from "../../contexts/ServiceMapContext"
import { useStore } from "../../state"
import { installDeck } from "../decks/DeckList"
import CardList from "./CardList"
import CardPath from "./CardPath"
import MassInput from "./MassInput"
import { useGetCardQuery } from "../../queryHooks"
import CardEdit from "./CardEdit"

const Search = Component(() => {
  const client = useContext(ClientContext)!
  const [cards, setCards] = useState<CardType[]>([])
  const [selected, setSelectedCard] = useState<CardType | null>(null)
  const serviceMap = useContext(ServiceMapContext)!
  const store = useStore()
  const topQuery = useGetCardQuery(store.searchTop)
  const activeCard = selected || topQuery.data || null
  const [bookmark] = useState<CardType | null>(null)
  const { card } = useParams()
  useEffect(() => {
    if (typeof card === "string" && Number.isInteger(+card)) {
      store.setSearchTop(+card)
    }
  }, [card])

  const setTop = async (top: CardType | number | null) => {
    store.setSearchTop(typeof top === "number" ? top : top?.id ?? null)
    setSelectedCard(null)
  }

  const handleClick = async (card: CardType) => {
    setSelectedCard(selected === card ? null : card)
  }

  const handleDoubleClick = async (card: CardType) => {
    setTop(card)
  }

  useEffect(() => {
    handleSearch()
  }, [store.searchTop])

  const handleLoad = async () => {
    if (activeCard && confirm("Are you sure you want to create a deck?")) {
      await installDeck(serviceMap, store, activeCard)
    }
  }

  async function handleCreate() {
    const [createdCardId] = await serviceMap.createCard(
      activeCard?.id ?? null,
      [{ front: "", back: "" }],
      {}
    )
    const cards = await handleSearch()
    const card = cards.find((card) => card.id === createdCardId)!
    setSelectedCard(card)
  }

  const handleDelete = async () => {
    if (activeCard && confirm("Are you sure you want to delete this card?")) {
      await serviceMap.remove(activeCard.id)
      if (store.searchTop === activeCard.id) {
        setTop(activeCard.parent)
      }
      setSelectedCard(null)
      await handleSearch()
    }
  }

  const handleSearch = async () => {
    setCards([])
    const cards = await serviceMap.getCardsByParent(store.searchTop)
    setCards(cards)
    return cards
  }

  if (client.websocket && !client.connected) {
    return <div>Not connected</div>
  }
  return (
    <div>
      <div
        style={{
          background:
            store.searchTop === activeCard?.id || null ? "#ccf" : "transparent",
        }}
      >
        {" "}
        <CardPath card={store.searchTop} setTop={setTop} />
      </div>
      <CardList
        cards={cards}
        selected={selected}
        handleClick={handleClick}
        handleDoubleClick={handleDoubleClick}
      />

      {activeCard?.id ?? "null"}
      <CardEdit
        activeCard={activeCard}
        setTop={setTop}
        setSelectedCard={setSelectedCard}
        handleCreate={handleCreate}
        handleDelete={handleDelete}
        handleLoad={handleLoad}
      />
    </div>
  )
})

export default Search

import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import HelpIcon from "@mui/icons-material/Help"
import HotelIcon from "@mui/icons-material/Hotel"
import NewReleasesIcon from "@mui/icons-material/NewReleases"
import RefreshIcon from "@mui/icons-material/Refresh"
import ShuffleIcon from "@mui/icons-material/Shuffle"
import { Toolbar } from "@mui/material"
import Button from "@scope/standard/ui/Button"
import Component from "@scope/standard/ui/Component"
import * as React from "react"
import { useContext } from "react"
import { CardType } from "../../../../common/domain/Card"
import { ServerServiceMap } from "../../../../server/src/serviceMap"
import Badge from "../../components/Badge"
import Checkbox from "../../components/Checkbox"
import { ServiceMapContext } from "../../contexts/ServiceMapContext"
import { DeckType } from "../../model/Deck"
import { StoreType } from "../../model/Store"
import { useStore } from "../../state"
import { saveScore } from "../../tools/saveScore"

async function reloadDecks(serviceMap: ServerServiceMap, store: StoreType) {
  const newDecks = await serviceMap.getDecks()
  while (store.decks.length) {
    store.deleteDeck(store.decks[0]!)
  }
  for (const deck of newDecks) {
    const topCard = await serviceMap.getCard(deck.top!)
    store.createDeck(deck.id, deck.top!, topCard.front)
  }
}
const refresh = async (
  serviceMap: ServerServiceMap,
  store: StoreType,
  deck: DeckType
) => {
  const cards = await serviceMap.getCardsByAncestor(deck.parentId)
  store.refreshDeck(deck, cards)
}

export async function installDeck(
  serviceMap: ServerServiceMap,
  store: StoreType,
  topCard: CardType
) {
  await saveScore(serviceMap, store, true)
  const deck = await serviceMap.createDeck({ top: topCard.id })
  const clientDeck = store.createDeck(deck.id, deck.top!, topCard.front)
  await refresh(serviceMap, store, clientDeck)
}

export default Component(() => {
  const serviceMap = useContext(ServiceMapContext)!
  const store = useStore()
  const decks = store.decks
  const handleClick = async (deck: DeckType) => {
    store.setSelectedDeck(deck)
  }

  const deleteDeck = async () => {
    if (store.deck) {
      await serviceMap.removeDeck(store.deck.id)
      await reloadDecks(serviceMap, store)
    }
  }
  async function reload() {
    await reloadDecks(serviceMap, store)
  }
  const handleFindNext = (event: any) => {
    store.setIsFindNext(event.target.checked)
  }
  const handleRandomNewClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    store.deck?.setRandomNew(event.target.checked)
  }
  const findInNew = (event: React.ChangeEvent<HTMLInputElement>) => {
    store.deck?.setFindInNew(event.target.checked)
  }
  const findInIdle = (event: React.ChangeEvent<HTMLInputElement>) => {
    store.deck?.setFindInIdle(event.target.checked)
  }
  return (
    <div>
      <ul>
        {decks.map((deck: DeckType, id) => (
          <li
            key={deck.id}
            onClick={() => handleClick(deck)}
            style={{
              backgroundColor:
                store.deck && store.deck.id === deck.id
                  ? "#ccc"
                  : "transparent",
            }}
          >
            {deck.id}, {deck.parentId}, {deck.name}, {deck.cards.length},
            <Badge
              color="primary"
              max={1e6}
              badgeContent={deck.cardsByState.available.length}
            >
              <HelpIcon />
            </Badge>
            , {deck.timeout}
          </li>
        ))}
      </ul>
      <Toolbar style={{ justifyContent: "space-around" }}>
        <Button
          aria-label="refresh"
          color="inherit"
          disabled={!store.deck}
          onClick={() => refresh(serviceMap, store, store.deck!)}
        >
          <RefreshIcon />
        </Button>
        <Button
          aria-label="delete"
          color="inherit"
          disabled={!store.deck}
          onClick={deleteDeck}
        >
          <DeleteForeverIcon />
        </Button>
      </Toolbar>
      <div>
        {store.deck && (
          <>
            <div>
              <Checkbox
                onChange={handleRandomNewClick}
                checked={store.deck.randomNew}
              >
                <ShuffleIcon />
                Random new
              </Checkbox>
            </div>
            <div>
              <Checkbox onChange={findInNew} checked={store.deck.findInNew}>
                <NewReleasesIcon />
                Find in new
              </Checkbox>
            </div>
            <div>
              <Checkbox onChange={findInIdle} checked={store.deck.findInIdle}>
                <HotelIcon />
                Find in idle
              </Checkbox>
            </div>
          </>
        )}
      </div>
      <Button aria-label="reload decks" color="inherit" onClick={reload}>
        <RefreshIcon />
        Reload decks from server
      </Button>
    </div>
  )
})
